import { useContext } from "react";

import { StagesContext } from "../../contexts/StageContext";
import { CaidasUploadsContext } from "../../contexts/CaidasUploadsContext";
import Button from "../Button";

const UploadCaidasFooter = () => {
	const { nextStage, previousStage } = useContext(StagesContext);
	const {
		isAnyFileUploaded,
		uploadedFiles,
		processFiles,
		loading,
		hasProcessingError,
		processed,
	} = useContext(CaidasUploadsContext);

	if (!isAnyFileUploaded) {
		return (
			<footer className="h-16 bg-white flex flex-row justify-between p-2">
				<Button onClick={previousStage}>Volver</Button>
			</footer>
		);
	}
	const isButtonDisabled = loading;
	const allFilesReadyToUpload = uploadedFiles >= 1 && processed && !hasProcessingError;
	const buttonLabel = (() => {
		if (loading) return "Procesando...";
		if (hasProcessingError)
			return "Se encontraron errores. Revise los archivos subidos";
		if (uploadedFiles >= 1 && processed && !hasProcessingError)
			return "Siguiente";
		if (uploadedFiles >= 1) return "Procesar archivos";
		return "";
	})();
	const handleClick = () => {
		if (allFilesReadyToUpload) return nextStage();
		processFiles();
	};
	return (
		<footer className="h-16 bg-white flex flex-row justify-between p-2">
			<Button onClick={previousStage}>Volver</Button>
			<Button onClick={handleClick} disabled={isButtonDisabled}>
				{buttonLabel}
			</Button>
		</footer>
	);
};

export default UploadCaidasFooter;
