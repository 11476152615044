import React, { useEffect, useRef, useState } from 'react';

const SingleDragAndDrop = ({ children, id, onHandleDrop, ...rest }) => {
	const [dragging, setDragging] = useState(false);
	const dropRef = useRef();

	useEffect(() => {
		const div = dropRef.current;
		let dragCounter = 0;

		const handleDragIn = e => {
			e.preventDefault();
			e.stopPropagation();
			dragCounter++;
			if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
				setDragging(true);
			}
		};

		const handleDragOut = e => {
			e.preventDefault();
			e.stopPropagation();
			dragCounter = Math.max(dragCounter - 1, 0);
			if (dragCounter === 0) {
				setDragging(false);
			}
		};

		const handleDrag = e => {
			e.preventDefault();
			e.stopPropagation();
		};

		const handleDrop = e => {
			e.preventDefault();
			e.stopPropagation();
			setDragging(false);
			dragCounter = 0;
			if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
				onHandleDrop(e.dataTransfer.files[0]);
				e.dataTransfer.clearData();
			}
		}

		div.addEventListener('dragenter', handleDragIn);
		div.addEventListener('dragleave', handleDragOut);
		div.addEventListener('dragover', handleDrag);
		div.addEventListener('drop', handleDrop);

		return () => {
			div.removeEventListener('dragenter', handleDragIn);
			div.removeEventListener('dragleave', handleDragOut);
			div.removeEventListener('dragover', handleDrag);
			div.removeEventListener('drop', handleDrop);
		}
	}, [onHandleDrop]);

	return (
		<div ref={dropRef} {...rest}>
			{children({ dragging })}
		</div>
	);
};

export default SingleDragAndDrop;
