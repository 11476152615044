import makeHeader from './make-header';

const parseCsv = unparsedData => {
	const rawData =
		unparsedData
			.split(/\r?\n/g)
			.filter(line => Boolean(line.trim()))
			.map(line => line.split(/\t|;/));
	const headers = rawData[0].map(makeHeader);
	const data = rawData.slice(1).map(row => row.slice(0, headers.length));
	const parsedData = data.map(row =>
		row.reduce(
			(memo, col, colIndex) => ({
				...memo,
				[headers[colIndex].key]: col
			}),
			{}
		)
	);
	return {
		headers,
		data,
		parsedData
	};
};

export default parseCsv;
