import { createContext, useEffect, useState } from 'react';
import when from '../util/promise-when';

import processCaidas from '../services/process-caidas';

export const CaidasUploadsContext = createContext();

const CaidasUploadsContextProvider = ({ children }) => {
	const [caidas, setCaidas] = useState();
	const [loading, setLoading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [uploadStage, setUploadStage] = useState();
	const [processed, setProcessed] = useState(false);
	const [processResults, setProcessResults] = useState({});
	const [caidasUploadResult, setCaidasUploadResult] = useState();

	const files = [caidas];

	const processFiles = async () => {
		setLoading(true);
		const [
			caidasResult,
		] = await when([
			caidas ? processCaidas(caidas) : Promise.resolve([]),
		]);
		setProcessResults({
			caidas: caidasResult,
		});
		setProcessed(true);
		setLoading(false);
	};

	useEffect(() => {
		const { caidas, ...rest } = processResults;
		setProcessed(false);
		setProcessResults(rest);
	}, [caidas]);

	const resetUploads = () => {
		setCaidas();
		setCaidasUploadResult();
		setProcessResults({});
	};

	const uploadToAPI = async () => {
		console.log('upload to api');
		setUploading(true);
		/// first upload remesa
		console.log(processResults);
		if (processResults.caidas.value.headers) {
			setUploadStage('caidas');
			const uploadResponse = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/sgr/caidas`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
				},
				body: JSON.stringify({
					parsedData: processResults.caidas.value.parsedData,
					headers: processResults.caidas.value.headers,
				}),
			});
			const uploadResult = await uploadResponse.json();
			setCaidasUploadResult(uploadResult);
		}
		setUploadStage();
		setUploading(false);
	};

	return (
		<CaidasUploadsContext.Provider
			value={{
				caidas,
				setCaidas,
				isAnyFileUploaded: files.some(Boolean),
				uploadedFiles: files.filter(Boolean).length,
				totalFiles: files.length,
				processFiles,
				loading,
				processResults,
				hasProcessingError: Boolean(
					Object.values(processResults)
						.filter(v => v && !v.resolved)
						.length
				),
				processed,
				resetUploads,
				uploadToAPI,
				uploading,
				uploadStage,
				caidasUploadResult,
			}}
		>
			{children}
		</CaidasUploadsContext.Provider>
	)
};

export default CaidasUploadsContextProvider;