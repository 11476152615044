const when = promises => {
	const wrappedPromises = promises.map(promise =>
		promise
			.then(value => ({
				resolved: true,
				value,
			}))
			.catch(reason => ({
				resolved: false,
				reason,
			}))
	);
	return Promise.all(wrappedPromises);
};

export default when;
