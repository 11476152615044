import { camel, snake, title } from 'case';

const makeHeader = h => ({
	id: snake(h),
	original: h,
	name: title(h),
	key: camel(h),
});

export default makeHeader;
