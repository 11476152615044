import { useContext } from "react";
import ReactDataGrid from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import { title } from 'case';

import { CaidasUploadsContext } from "../../contexts/CaidasUploadsContext";

const ReviewCaidas = () => {
	const { processResults } = useContext(CaidasUploadsContext);

	const { caidas } = processResults;

	const data = { caidas };

	return (
		<div className="h-full flex flex-col p-3">
			<h1 className="text-4xl">Revisar los datos cargados</h1>
			<p className="mb-1">
				Revise los datos cargados en la etapa anterior para verificar
			</p>
			{Object.keys(data).filter(k => data[k].value.headers).map(k => (
				<div key={k} className="h-full flex flex-col">
					<h2 className="text-2xl">Archivo de "{title(k)}"</h2>
					<ReactDataGrid
						className="mb-5 h-full flex-1"
						columns={data[k].value.headers.map(h => ({ ...h, width: 120, resizable: true}))}
						rows={data[k].value.parsedData}
					/>
				</div>
			))}
		</div>
	);
};

export default ReviewCaidas;
