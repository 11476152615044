import Footer from "./Footer/";
import Steps from "./Steps";

const Layout = () => (
	<div className="flex flex-col h-screen justify-between">
		<header
			className="h-20 text-base p-1 flex items-center justify-between flex-wrap"
			style={{ backgroundColor: '#F1F1EC' }}
		>
			<div className="flex items-center flex-no-shrink text-white mr-6">
				<img className="h-20 mr-2" src="https://www.unicred.com.ar/dist/images/logo-unicred-web-secundario.png" alt="Unicred logo" />
			</div>
		</header>
		<main className="mb-auto h-full overflow-scroll">
			<Steps />
		</main>
		<Footer />
	</div>
);

export default Layout;