import parseCsv from './parse-csv';
import readFile from './read-file';

const csvProcessor = ({
	expectedHeaderCount,
	expectedHeaders,
}) => async file => {
	const rawData = await readFile(file);
	const { headers, data, parsedData } = parseCsv(rawData);
	if (headers.length !== expectedHeaderCount) throw new Error('invalid header count');
	const missingHeaders = expectedHeaders.filter(
		rawHeader => !headers.find(h => h.original === rawHeader)
	);
	if (missingHeaders.length) throw new Error('missing headers');
	if (!parsedData.length) throw new Error('no data was found');
	return { headers, data, parsedData };
};

export default csvProcessor;