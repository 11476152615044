import { createContext } from 'react';
import useMergeState from '../hooks/use-merge-stage';
import getIn from '../util/get-in';

export const StagesContext = createContext();

const DEFAULT_INITIAL_PATH = '0';

const fixPath = path => path.replace(/^\./, '');

const pathForStage = ({ stages, lookupStage, startingPath = '' }) => {
	const startingStage = getIn({ value: stages, path: startingPath }) || stages;
	if (Array.isArray(startingStage)) {
		const path =
			startingStage
				.map((stage, i) => {
					if (stage === lookupStage) return fixPath(`${startingPath}.${i}`);
					if (typeof stage === 'string') return false;
					return pathForStage({
						stages: startingStage[i],
						lookupStage,
						startingPath: fixPath(`${startingPath}.${i}`),
					});
				})
				.filter(Boolean)[0];
		return path;
	}
	const path =
		Object.keys(startingStage)
			.map(key => {
				return pathForStage({
					stages: startingStage[key],
					lookupStage,
					startingPath: fixPath(`${startingPath}.${key}`),
				})
			})
			.filter(Boolean)[0];
	return path;
};

const parentPath = (path, n = 1) => path.split('.').slice(0, -1 * n).join('.');
const pathKey = path => parseInt(path.split('.').slice(-1)[0], 10);

const StagesContextProvider = ({
	children,
	initialStage,
	stages,
	onReset,
}) => {
	const initialPath = (() => {
		if (!initialStage) return DEFAULT_INITIAL_PATH;
		return pathForStage({ stages, lookupStage: initialStage, });
	})();
	
	const [stageData, setStage] = useMergeState({
		stage: initialStage,
		path: initialPath,
	});

	const resetStages = () => {
		const shouldReset = onReset && onReset({ currentStage: stageData.stage });
		if (shouldReset !== false) {
			setStage({ stage: initialStage, path: initialPath });
		}
	};
	const nextStage = action => {
		const currentStageIndex = pathKey(stageData.path);
		const parentStage = getIn({ value: stages, path: parentPath(stageData.path) }) || stages;
		if (!action) {
			const nextStage = parentStage[(currentStageIndex + 1) % parentStage.length];
			return setStage({
				stage: nextStage,
				path: pathForStage({ stages, lookupStage: nextStage })
			});
		}
		const nextStage = getIn({
			value: parentStage,
			path: `${(currentStageIndex + 1) % parentStage.length}.${action}`
		})[0];
		return setStage({
			stage: nextStage,
			path: pathForStage({ stages, lookupStage: nextStage })
		});
	};
	const previousStage = () => {
		const currentStageIndex = pathKey(stageData.path);
		const parentStage = getIn({ value: stages, path: parentPath(stageData.path) }) || stages;
		if (currentStageIndex === 0) {
			const basePath = parentPath(stageData.path, 2);
			const prevStagePath = basePath.replace(/\d$/, pathKey(basePath) - 1);
			return setStage({
				stage: getIn({ value: stages, path: prevStagePath }),
				path: prevStagePath,
			});
		}
		const prevStage = parentStage[(currentStageIndex - 1) % parentStage.length];
		return setStage({
			stage: prevStage,
			path: pathForStage({ stages, lookupStage: prevStage })
		});
	};

	return (
		<StagesContext.Provider
			value={{
				stage: stageData.stage,
				nextStage,
				previousStage,
				resetStages,
			}}
		>
			{children}
		</StagesContext.Provider>
	)
};

export default StagesContextProvider;