import { useContext } from "react";
import Button from "../components/Button";
import { StagesContext } from "../contexts/StageContext";

const Home = () => {
	const { nextStage } = useContext(StagesContext);
	return (
		<div className="h-full flex flex-col p-3">
			<h1 className="text-4xl">Hola!</h1>
			<p className="mb-10">En este lugar se cargan los archivos de caidas</p>
			<Button className="mb-5" onClick={() => nextStage('caidas')}>
				Cargar archivo de caidas
			</Button>
		</div>
	);
};

export default Home;