import { useContext } from 'react';
import Button from '../../../components/Button';
import SingleDragAndDrop from '../../../components/SingleDragAndDrop';
import { CaidasUploadsContext } from '../../../contexts/CaidasUploadsContext';
import UploadContent from './UploadContent';

const UploadCaidas = () => {
	const {
		caidas,
		setCaidas,
		processResults,

		uploadedFiles,
		loading,
		hasProcessingError,
		processed,
		resetUploads
	} = useContext(CaidasUploadsContext);
	const statusText = (() => {
		if (loading)
			return <p className="text-blue-300 text-xl mb-5">Procesando...</p>;
		if (hasProcessingError)
			return (
				<p className="text-red-400 text-xl mb-5">
					Se encontraron errores. Revise los archivos subidos
				</p>
			);
		if (uploadedFiles >= 1 && processed && !hasProcessingError)
			return (
				<p className="text-green-500 text-xl mb-5">
					No se encontraron errores. Presione "Siguiente" para continuar
				</p>
			);
		if (uploadedFiles >= 1)
			return <p className="text-green-500 text-xl mb-5">Listo para procesar</p>;
		return null;
	})();
	return (
		<div className="h-full place-items-auto p-3">
			<h1 className="text-4xl">Carga de Archivos de Caidas</h1>
			<p className="mb-10">Arrastre los archivos correspondientes</p>
			<div className="flex flex-row mb-5">
				<SingleDragAndDrop
					className="flex-1"
					onHandleDrop={setCaidas}>
					{({ dragging }) => (
						<UploadContent
							results={processResults.caidas}
							dragging={dragging}
							file={caidas}
							fileName="caidas"
						/>
					)}
				</SingleDragAndDrop>
			</div>
			{statusText}
			<Button onClick={resetUploads}>
				Resetear archivos
			</Button>
		</div>
	);
};

export default UploadCaidas;