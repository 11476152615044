import { useContext } from "react";

import { StagesContext } from "../contexts/StageContext";

import Home from "../screens/Home";

import ReviewCaidas from "../screens/Caidas/ReviewCaidas";
import UploadCaidas from "../screens/Caidas/UploadCaidas";
import UploadCaidasFiles from "../screens/Caidas/UploadCaidasFiles";

const Steps = () => {
	const { stage } = useContext(StagesContext);
	if (stage === 'home') return <Home />;
	if (stage === 'upload-caidas') return <UploadCaidas />;
	if (stage === 'review-caidas') return <ReviewCaidas />;
	if (stage === 'upload-caidas-files') return <UploadCaidasFiles />;
	return (
		<h1>It works!</h1>
	);
};

export default Steps;
