import { useContext } from 'react';
import 'react-data-grid/dist/react-data-grid.css';
import { title } from 'case';

import { CaidasUploadsContext } from '../../contexts/CaidasUploadsContext';
import clsx from 'clsx';
import Button from '../../components/Button';
import { StagesContext } from '../../contexts/StageContext';

const Spinner = () => (
	<svg
		className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24">
		<circle
			className="opacity-25"
			cx="12"
			cy="12"
			r="10"
			stroke="currentColor"
			strokeWidth="4"></circle>
		<path
			className="opacity-75"
			fill="currentColor"
			d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
	</svg>
);

const UploadCaidasFiles = () => {
	const {
		processResults,
		uploading,
		uploadStage,
		resetUploads,
		caidasUploadResult
	} = useContext(CaidasUploadsContext);
	const { resetStages } = useContext(StagesContext);

	const { caidas } = processResults;

	const data = [
		['caidas', caidas],
	];
	// .filter(([$, v]) => v.value.headers);

	const uploadingIndex = data.findIndex(
		([key, value]) => value.value.headers && uploadStage === key
	);

	const startAgain = () => {
		resetUploads();
		resetStages();
	};

	return (
		<div className="h-full flex flex-col p-3">
			<h1 className="text-4xl">Subiendo archivos</h1>
			<p className="mb-1">Los archivos cargados se estan cargando en la plataforma</p>
			{data.map(([key, value], index) => (
				<h4
					key={key}
					className={clsx(
						'text-lg',
						!value.value.headers && 'text-gray-500',
						value.value.headers && (index < uploadingIndex || uploadingIndex === -1) && 'text-green-500',
						index === uploadingIndex && 'text-xl mb-3 mt-3',
					)}>
					{uploadingIndex === -1 ? (
						value.value.headers ? (
							`${value.value.headers ? '✅' : ''} Archivo "${title(key)}" ${value.value.headers ? 'subido exitosamente' : ''}`.trim()
						) : (
							`Subir archivo ${title(key)}`
						)
					) : (index < uploadingIndex ? (
						`${value.value.headers ? '✅' : ''} Archivo "${title(key)}" ${value.value.headers ? 'subido exitosamente' : ''}`.trim()
					) : index === uploadingIndex ? (
						<>
							{index === uploadingIndex && <Spinner />}
							Subiendo archivo "{title(key)}"
						</>
					) : (
						`Subir archivo ${title(key)}`
					))}
				</h4>
			))}
			{!uploadStage && !uploading && (
				<>
					<p className="text-green-500">Los archivos cargados se han subido exitosamente</p>
					<table style={{ width: '250px' }}>
						<tbody>
							<tr>
								<th>Envios Exitosos</th>
								<th>{caidasUploadResult.success}</th>
							</tr>
							<tr>
								<th>Envios Fallidos</th>
								<th>
									<ul>
										{!caidasUploadResult.failed.length && '0'}
										{caidasUploadResult.failed.map(asoc => (
											<li key={asoc}>{asoc}</li>
										))}
									</ul>
								</th>
							</tr>
						</tbody>
					</table>
					<Button onClick={startAgain}>Iniciar una nueva carga</Button>
				</>
			)}
		</div>
	);
};

export default UploadCaidasFiles;
