import csvProcessor from "./csv-processor";

const CAIDAS_HEADERS = [
	'Firmante',
	'FechaDep.',
	'FechaAcr.',
	'No.OP.',
	'T.Inst.',
	'Asoc',
	'NombreoR.S.',
	'Bco.',
	'Suc.',
	'No.Cheque',
	'Moneda',
	'Importe',
];
const CAIDAS_HEADER_COUNT = CAIDAS_HEADERS.length;

const processCaidas = csvProcessor({
	expectedHeaderCount: CAIDAS_HEADER_COUNT,
	expectedHeaders: CAIDAS_HEADERS,
});

export default processCaidas;
