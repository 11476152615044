import { useContext } from "react";

import { StagesContext } from "../../contexts/StageContext";
import UploadCaidasFooter from "./UploadCaidasFooter";
import ReviewCaidasFooter from "./ReviewCaidasFooter";

const Footer = () => {
	const { stage } = useContext(StagesContext);

	if (stage === "upload-caidas") return <UploadCaidasFooter />;
	if (stage === "review-caidas") return <ReviewCaidasFooter />;
	return null;
};

export default Footer;
