const getIn = ({ value, path, defaultValue }) =>
	String(path).split('.').reduce((acc, v) => {
		try {
			acc = acc[v]
		} catch (e) {
			return defaultValue
		}
		return acc
	}, value);

export default getIn;