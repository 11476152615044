import { useContext } from "react";

import { StagesContext } from "../../contexts/StageContext";
import { CaidasUploadsContext } from "../../contexts/CaidasUploadsContext";

import Button from '../Button';

const ReviewCaidasFooter = () => {
	const { nextStage, previousStage } = useContext(StagesContext);
	const { uploading, uploadToAPI } = useContext(CaidasUploadsContext);
	const isButtonDisabled = uploading;
	const buttonLabel = "Confirmar y subir";
	const nextButtonHandler = () => {
		uploadToAPI();
		nextStage();
	};
	return (
		<footer className="h-16 bg-white flex flex-row justify-between p-2">
			<Button onClick={previousStage}>Volver</Button>
			<Button onClick={nextButtonHandler} disabled={isButtonDisabled}>
				{buttonLabel}
			</Button>
		</footer>
	);
};

export default ReviewCaidasFooter;