import clsx from 'clsx';

const Button = ({ disabled, children, className, ...rest }) => {
	const buttonClassName = clsx(
		disabled && "cursor-not-allowed",
		!disabled && "transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:shadow-outline hover:text-white",
		"btn-outline-primary border border-blue-700 text-blue-700 font-normal py-2 px-4 rounded",
		className,
	);
	return (
		<button
			type="button"
			disabled={disabled}
			className={buttonClassName}
			{...rest}>
			{children}
		</button>
	);
};

export default Button;