import clsx from "clsx";

const UploadContent = ({ dragging, file, fileName, results }) => {
	const header = (
		<span className="text-2xl block">ARCHIVO DE "{fileName.toUpperCase()}"</span>
	);
	const body = (() => {
		if (!dragging && !file) {
			return <span  className="block">Arrastre el archivo de "{fileName}" aquí</span>;
		}
		if (dragging && !file) {
			return <span  className="block">Suelte el archivo aquí</span>;
		}
		if (dragging && file) {
			return <span  className="block">Suelte el archivo aquí para reemplazar el actual</span>;
		}
		const statusText = (() => {
			if (!results) return 'Archivo registrado ✅';
			if (results.resolved) return 'Archivo listo para subir ✅';
			return `Archivo con problemas. Subalo nuevamente o revise el formato (${results.reason})`;
		})();
		const statusClassName = clsx(
			"text-md block",
			results && results.reason && "text-red-400",
		);
		return (
			<>
				<span className={statusClassName}>{statusText}</span>
				<span className="text-base text-gray-500 block">Archivo: {file.name}</span>
			</>
		);
	})();
	const containerClassName = clsx(
		"p-4 flex flex-col rounded border border-black border-solid mr-1",
		dragging && "border-dashed bg-indigo-100",
		results && results.resolved && results.value.data && "bg-green-100"
		// !dragging && "border-transparent"
	)
	return (
		<div className={containerClassName} style={{ height: '180px'}}>
			{header}
			{body}
		</div>
	)
};

export default UploadContent;