import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import App from './App';
import Reply from "./screens/Reply";

const AppRouter = () => (
	<Router>
		<Switch>
			<Route path="/upload">
				<App />
			</Route>
			<Route path="/reply/:eventId">
				<Reply />
			</Route>
		</Switch>
	</Router>
);

export default AppRouter;
