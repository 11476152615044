import { useEffect, useState } from 'react';

import StagesContextProvider from "./contexts/StageContext";
import CaidasUploadsContextProvider from "./contexts/CaidasUploadsContext";

import Layout from "./components/Layout";
import Button from './components/Button';

const INITIAL_STAGE = 'home';
const STAGES = [
	'home',
	{
		'caidas': ['upload-caidas', 'review-caidas', 'upload-caidas-files'],
	},
];

const App = () => {
	const [loggedIn, setLoggedIn] = useState((() => {
		const lsPassword = localStorage.getItem('unicred:loggedIn');
		if (!lsPassword) return false;
		const time = parseInt(lsPassword, 10);
		if (!time) return false;
		return time > Date.now();
	})());
	const [password, setPassword] = useState('');

	const submitPassword = e => {
		e.preventDefault();
		if (password === process.env.REACT_APP_PASSWORD) {
			localStorage.setItem('unicred:loggedIn', Date.now() + 1 * 30 * 24 * 60 * 60 * 1000);
			setLoggedIn(true);
		} else {
			alert('Contraseña incorrecta');
		}
	};

	useEffect(() => {
		if (loggedIn) return;
		localStorage.removeItem('unicred:loggedIn');
	}, []);


	if (!loggedIn) {
		return (
			<div className="container mx-auto max-w-screen-md text-center">
				<img className="mx-auto" src="https://unicred.com.ar/dist/images/logo-unicred-web-secundario.png" alt="Unicred Logo" />
				<form onSubmit={submitPassword}>
					<label
						htmlFor="password"
						className="block mb-2 text-lg text-gray-600 dark:text-gray-400">
							Ingrese Contraseña
					</label>
          <input
						value={password}
						onChange={e => setPassword(e.target.value)}
						type="password"
						name="password"
						id="password"
						placeholder="Contraseña"
						required
						className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
					<Button className="mt-2" type="submit">Ingresar</Button>
				</form>
			</div>
		)
	}

	return (
		<StagesContextProvider
			initialStage={INITIAL_STAGE}
			stages={STAGES}
		>
			<CaidasUploadsContextProvider>
				<Layout />
			</CaidasUploadsContextProvider>
		</StagesContextProvider>
	);
};

export default App;
