import { useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import clx from 'clsx';
import { useState } from "react";
import Button from "../components/Button";

const undefinedOrNull = v => typeof v === 'undefined' || v === null;

const Reply = () => {
	const [selectedChecks, setSelectedChecks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const location = useLocation();
	const { eventId } = useParams();

	const { token } = location.search.replace(/^\?/, '').split('&').reduce((memo, v) => {
		const [key, value] = v.split('=');
		return {
			...memo,
			[key]: value,
		};
	}, {});

	const { data: event, error } = useSWR(() => `${process.env.REACT_APP_ENDPOINT}/api/v1/events/${eventId}?token=${token}`);

	const handleChange = i => () => {
		const wasSelected = selectedChecks.findIndex(c => c === i);
		if (wasSelected === -1) {
			setSelectedChecks([...selectedChecks, i]);
		} else {
			setSelectedChecks(selectedChecks.filter(c => c !== i));
		}
	};

	const handleSubmit = amount => async () => {
		setLoading(true);
		await fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/events/${eventId}`, {
			method: 'PATCH',
			body: JSON.stringify({
				data: {
					...event.data,
					reply: amount === 'none' ? [] : selectedChecks.slice().sort(),
				}
			}),
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		});
		setLoading(false);
		setDone(true);
	};
	
	const content = (() => {
		if (error) {
			return <h1 className="text-center text-4xl mt-8">Ocurrió un error, intente nuevamente</h1>;
		}
		if (!event) {
			return <h1 className="text-center text-4xl mt-8">Cargando...</h1>;
		}
		if (event.type !== 'sgr-caidas') {
			return <h1 className="text-center text-4xl mt-8">Ocurrió un error, intente nuevamente</h1>;
		}
		if (event.data.reply) {
			return <h1 className="text-center text-4xl mt-8">Ya se ha enviado una respuesta, gracias!</h1>;
		}
		if (done) {
			return <h1 className="text-center text-4xl mt-8">Se ha enviado su respuesta, gracias!</h1>;
		}
		if (loading) {
			return <h1 className="text-center text-4xl mt-8">Cargando...</h1>;
		}

		const rows = event.data.d.map((c, i) => {
			const isSelected = !undefinedOrNull(selectedChecks.find(e => e === i));
			return (
				<tr key={i}>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.firmante}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.fechaDep}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.fechaAcr}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.noOP}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.bco}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.suc}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.noCheque}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						{c.moneda}{c.importe}
					</td>
					<td className={clx("h-9 align-middle", isSelected && 'bg-yellow-200')}>
						<input type="checkbox" onChange={handleChange(i)} />
					</td>
				</tr>
			);
		});

		return (
			<>
				<h1 className="text-4xl mb-4">Seleccione los cheques a rescatar</h1>
				<table className="mx-auto w-full">
					<thead>
						<tr>
							<th>Firmante</th>
							<th>Fecha Depósito</th>
							<th>Fecha Acreditación</th>
							<th>Nro Op</th>
							<th>Banco</th>
							<th>Sucursal</th>
							<th>Nro Cheque</th>
							<th>Importe</th>
							<th>Rescatar</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				<Button
					disabled={!selectedChecks.length}
					className="w-full"
					onClick={handleSubmit()}>
					Rescatar {selectedChecks.length} cheques
				</Button>
				<Button className="w-full" onClick={handleSubmit('none')}>
					No deseo rescatar ningún cheque
				</Button>
			</>
		);
	})();

	return (
		<div className="container mx-auto max-w-screen-md text-center">
			<img className="text-center mx-auto" src="https://unicred.com.ar/dist/images/logo-unicred-web-secundario.png" alt="Unicred Logo" />
			{content}
		</div>
	);
};

export default Reply;